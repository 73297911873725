const CorporateScreens = {
  LIST_CORPORATE: {
    PATH: '/corporate/list',
  },
  DETAIL_CORPORATE: {
    PATH: '/corporate/list/detail/:id/:tab',
  },
  REGISTER_CORPORATE: {
    PATH: '/corporate/list/register',
  },
  REGISTER_DRAFT_CORPORATE: {
    PATH: '/corporate/list/register/:id',
    buildPath: (id: string) => `/corporate/list/register/${id}`,
  },
}

export default CorporateScreens
