import { Action } from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace CorporateRedux {
  const STORAGE_KEY = 'hermes-corporate'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetListCorporate: '[Get List Corporate] Action',
    IsLoading: '[IsLoading] Action',
    GetCorporateDetail: '[Get Detail Corporate] Action',
  }

  const initialCorporateState: ICorporateState = {
    data: undefined,
    corporate: undefined,
    isLoading: undefined,
    message: undefined,
  }

  export interface ICorporateState {
    data?: any
    corporate?: any
    isLoading?: boolean
    message?: string
  }

  export const reducer = persistReducer(
    { storage, key: STORAGE_KEY, whitelist: ['data', 'corporate', 'isLoading', 'message'] },
    (
      state: ICorporateState = initialCorporateState,
      action: ActionWithPayload<ICorporateState>
    ) => {
      const data = action.payload?.data
      const corporate = action.payload?.corporate
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading

      switch (action.type) {
        case actionTypes.GetListCorporate:
          return { ...state, data, message }
        case actionTypes.GetCorporateDetail:
          return { ...state, corporate, message }
        case actionTypes.IsLoading:
          return { ...state, isLoading }
        default:
          return state
      }
    }
  )

  export const actions = {
    getListCorporate: (data: any, message: string) => ({
      type: actionTypes.GetListCorporate,
      payload: { data, message },
    }),
    getCorporateDetail: (corporate: any, message: string) => ({
      type: actionTypes.GetCorporateDetail,
      payload: { corporate, message },
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: { isLoading },
    }),
  }
}

export default CorporateRedux
