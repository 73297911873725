const StoreScreens = {
  STORE_LIST: {
    PATH: '/store/list',
  },
  STORE_REGISTER: {
    PATH: '/store/list/register/:id',
    buildPath: (id: string) => `/store/list/register/${id}`,
  },
  STORE_DETAIL: {
    PATH: '/store/list/detail/:id/:tab/:merchantId',
    buildPath: (id: string, tab: string, merchantId: string) =>
      `/store/list/detail/${id}/${tab}/${merchantId}`,
  },
  STORE_SALES_AND_CATEGORY: {
    PATH: '/store/list/sales-and-price/:id/:merchantId',
    buildPath: (id: string, merchantId: string) =>
      `/store/list/sales-and-price/${id}/${merchantId}`,
  },
  CATEGORY_MENU_LIST: {
    PATH: '/store/list/category-menu-list/:id/:merchantId',
    buildPath: (id: string, merchantId: string) =>
      `/store/list/category-menu-list/${id}/${merchantId}`,
  },
  ADD_MENU: {
    PATH: '/store/list/category-menu/:id/:merchantId/:channelId/add-menu',
    buildPath: (id: string, merchantId: string, channelId: string) =>
      `/store/list/category-menu/${id}/${merchantId}/${channelId}/add-menu`,
  },
  LIST_MENU: {
    PATH: '/store/list/category-menu/:id/:merchantId/:channelId/menu',
    buildPath: (id: string, merchantId: string, channelId: string) =>
      `/store/list/category-menu/${id}/${merchantId}/${channelId}/menu`,
  },
  UPDATE_MENU: {
    PATH: '/store/list/category-menu/:id/:merchantId/:channelId/menu/:menuId',
    buildPath: (id: string, merchantId: string, channelId: string, menuId: string) =>
      `/store/list/category-menu/${id}/${merchantId}/${channelId}/menu/${menuId}`,
  },
  STORE_VERIFICATION_LIST: {
    PATH: '/store/verification',
  },
  STORE_VERIFICATION_DETAIL: {
    PATH: '/store/verification/:id/:tab',
  },
  STORE_MANAGE_STOCK: {
    PATH: '/store/list/detail/:id/manage-stock/:merchantId/:tab',
  },
  SETUP_DATA_BANK: {
    PATH: '/setup-data-bank',
  },
  ORDER_HISTORY: {
    PATH: '/store/order-history/',
  },
  DETAIL_ORDER_HISTORY: {
    PATH: '/store/order-history/detail/:id',
    buildPath: (id: string) => `/store/order-history/detail/${id}`,
  },
  STORE_DISCOUNT_LIST: {
    PATH: '/store/list/discount/:id/:merchantId',
    buildPath: (id: string, merchantId: string) => `/store/list/discount/${id}/${merchantId}`,
    TITLE: 'List Diskon Menu',
    TITLE_ALT: 'Diskon Menu',
  },
  STORE_DISCOUNT_CREATE: {
    PATH: '/store/list/discount/:id/:merchantId/create',
    buildPath: (id: string, merchantId: string) =>
      `/store/list/discount/${id}/${merchantId}/create`,
    TITLE: 'Tambah Diskon Menu',
  },
  STORE_DISCOUNT_DETAIL: {
    PATH: '/store/list/discount/:id/:merchantId/detail/:discountId',
    buildPath: (id: string, merchantId: string, discountId?: string) =>
      `/store/list/discount/${id}/${merchantId}/detail/${discountId}`,
    TITLE: 'Detail Diskon Menu',
  },
}

export default StoreScreens
