import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace RolesRedux {
  const STORAGE_KEY = 'hermes-roles'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetListRole: '[Get List Role] Action',
    IsLoading: '[IsLoading Role] Action',
    SetMessage: '[Set Message Role] Action',
  }

  const initialBrandState: IRoleState = {
    data: undefined,
    isLoading: false,
    message: '',
  }

  export interface IRoleState {
    data?: any
    isLoading?: boolean
    message?: string
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['roles', 'isLoading', 'message'],
    },
    (state: IRoleState = initialBrandState, action: ActionWithPayload<IRoleState> = {type: ''}) => {
      const data = action.payload?.data
      const isLoading = action.payload?.isLoading
      const message = action.payload?.message

      switch (action.type) {
        case actionTypes.GetListRole:
          return {...state, data, isLoading: false}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        case actionTypes.SetMessage:
          return {...state, message}
        default:
          return state
      }
    }
  )

  export const actions = {
    getListRole: (data: any) => ({
      type: actionTypes.GetListRole,
      payload: {data},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
    setMessage: (message: string) => ({type: actionTypes.SetMessage, payload: {message}}),
  }
}

export default RolesRedux
