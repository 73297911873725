import {ListBrand} from './components/ListBrand'

const BrandScreens = {
  LIST_BRAND: {
    COMPONENT: ListBrand,
    PATH: '/brand/list',
  },
  REGISTER_BRAND: {
    PATH: '/brand/list/register/:id',
    buildPath: (id: string) => `/brand/list/register/${id}`,
  },
  BRAND_DETAIL: {
    PATH: '/brand/list/detail/:id/:tab',
    buildPath: (id: string, tab: string) => `/brand/list/detail/${id}/${tab}`,
  },
  BRAND_SALES_AND_CATEGORY: {
    PATH: '/brand/list/detail/sales-and-price/:id/:tab',
    buildPath: (id: string, tab: string) => `/brand/list/detail/sales-and-price/${id}/${tab}`,
  },
  BRAND_SALES_MODE_CREATE: {
    PATH: '/brand/list/detail/sales-and-price/:brand_id/create-sales-channel',
    buildPath: (brand_id: string) =>
      `/brand/list/detail/sales-and-price/${brand_id}/create-sales-channel`,
  },
  BRAND_SALES_MODE_DETAIL: {
    PATH: '/brand/list/detail/sales-and-price/:brand_id/list-sales-channel/:id',
    buildPath: (brand_id: string, id?: string) =>
      `/brand/list/detail/sales-and-price/${brand_id}/list-sales-channel/${id}`,
  },
  BRAND_CATEGORY_MODE_CREATE: {
    PATH: '/brand/list/detail/sales-and-price/:brand_id/create-category-price',
    buildPath: (brand_id: string) =>
      `/brand/list/detail/sales-and-price/${brand_id}/create-category-price`,
  },
  BRAND_CATEGORY_MODE_DETAIL: {
    PATH: '/brand/list/detail/sales-and-price/:brand_id/detail/:id',
    buildPath: (brand_id: string, id: string) =>
      `/brand/list/detail/sales-and-price/${brand_id}/detail/${id}`,
  },
  BRAND_MENU_AND_VARIATION: {
    PATH: '/brand/list/detail/:id/:salesChannelId/menu-and-variation/:tab',
    buildPath: (brand_id: string, sales_channel_id: string, tab: string) =>
      `/brand/list/detail/${brand_id}/${sales_channel_id}/menu-and-variation/${tab}`,
  },
  ADD_VARIATION: {
    PATH: '/brand/list/detail/:id/:salesChannelId/menu-and-variation/variation/add',
    buildPath: (id: string, sales_channel_id: string) =>
      `/brand/list/detail/${id}/${sales_channel_id}/menu-and-variation/variation/add`,
  },
  VARIATION_DETAIL: {
    PATH: '/brand/list/detail/:id/:salesChannelId/menu-and-variation/variation/:varid',
    buildPath: (id: string, sales_channel_id: string, variation_id: string) =>
      `/brand/list/detail/${id}/${sales_channel_id}/menu-and-variation/variation/${variation_id}`,
  },
  SALES_CHANNEL_MENU: {
    PATH: '/brand/list/detail/:id/list-sales-channel-menu',
    buildPath: (id: string) => `/brand/list/detail/${id}/list-sales-channel-menu`,
  },
  COPY_CATEGORY_MENU: {
    PATH: '/brand/list/detail/:id/category-menu/copy/:salesChannelId',
  },
  LIST_MENU: {
    PATH: '/brand/list/detail/:id/category-menu/:salesChannelId/:categoryId/menu',
  },
  ADD_MENU: {
    PATH: '/brand/list/detail/:id/category-menu/:salesChannelId/:categoryId/menu/add-menu',
  },
  UPDATE_MENU: {
    PATH: '/brand/list/detail/:id/category-menu/:salesChannelId/:categoryId/menu/:menuId',
  },
  LIST_VERIFICATION_BRAND: {
    PATH: '/brand/verification',
  },
  BRAND_VERIFICATION_DETAIL: {
    PATH: '/brand/verification/:id/:tab',
  },
  BRAND_PROMO_CREATE: {
    PATH: '/brand/list/detail/:id/promo/create',
    buildPath: (id?: string) => `/brand/list/detail/${id}/promo/create`,
    TITLE: 'Tambah Promo Brand',
  },
  BRAND_PROMO_DETAIL: {
    PATH: '/brand/list/detail/:id/promo/detail/:discountId',
    buildPath: (id?: string, discountId?: string) =>
      `/brand/list/detail/${id}/promo/detail/${discountId}`,
    TITLE: 'Detail Promo Brand',
  },
  BRAND_SAP: {
    PATH: '/brand/list/detail/sap/:id/:tab',
    buildPath: (id: string, tab: string) => `/brand/list/detail/sap/${id}/${tab}`,
  },
}

export const BrandSapTabs = {
  BRAND_STORE: {
    LABEL: 'Mapping Brand & Store',
    SLUG: 'brand-store',
    PATH: BrandScreens.BRAND_SAP.buildPath(':id', 'brand-store'),
    buildPath: (id: string) => BrandScreens.BRAND_SAP.buildPath(id, 'brand-store'),
  },
  PAYMENT_METHOD: {
    LABEL: 'Mapping Metode Pembayaran',
    SLUG: 'payment-method',
    PATH: BrandScreens.BRAND_SAP.buildPath(':id', 'payment-method'),
    buildPath: (id: string) => BrandScreens.BRAND_SAP.buildPath(id, 'payment-method'),
  },
  SALES_MODE: {
    LABEL: 'Mapping Mode Penjualan',
    SLUG: 'sales-mode',
    PATH: BrandScreens.BRAND_SAP.buildPath(':id', 'sales-mode'),
    buildPath: (id: string) => BrandScreens.BRAND_SAP.buildPath(id, 'sales-mode'),
  },
  MENU: {
    LABEL: 'Mapping Menu',
    SLUG: 'menu',
    PATH: BrandScreens.BRAND_SAP.buildPath(':id', 'menu'),
    buildPath: (id: string) => BrandScreens.BRAND_SAP.buildPath(id, 'menu'),
  },
  VARIATION: {
    LABEL: 'Mapping Variasi',
    SLUG: 'variation',
    PATH: BrandScreens.BRAND_SAP.buildPath(':id', 'variation'),
    buildPath: (id: string) => BrandScreens.BRAND_SAP.buildPath(id, 'variation'),
  },
  GENERATE_SAP: {
    LABEL: 'Generate Data SAP',
    SLUG: 'generate-sap',
    PATH: BrandScreens.BRAND_SAP.buildPath(':id', 'generate-sap'),
    buildPath: (id: string) => BrandScreens.BRAND_SAP.buildPath(id, 'generate-sap'),
  },
}

export default BrandScreens
