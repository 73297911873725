import axios from 'axios'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {appendAllFormData} from 'src/app/utils/input-utils'
import {Store} from '../../store/models/Store'
import {
  Brand,
  BrandFormData,
  BrandGroupCategory,
  BrandStatus,
  LineOfBusiness,
  POSSetting,
} from '../models/Brand'
import {MenuPriceCategoryStatus} from '../models/Menu'

export const BRAND = `/api/v1/merchants/merchants`
export const STORE = `/api/v1/merchants/stores`
export const LINE_OF_BUSINESS = `/api/v1/merchants/lob`

export function getListLOB(
  props: {
    page: number
    limit: number
    search?: string
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<LineOfBusiness>>>(LINE_OF_BUSINESS, {
    params: {...props, status: 'ACTIVE'},
    cancelToken,
  })
}

export function getListBrand(
  query: {
    page: number
    limit: number
    search?: string
    group_id?: string
    group_category?: BrandGroupCategory
    status?: BrandStatus
    statuses?: string[]
    search_fields?: string[]
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<Brand>>>(BRAND, {
    params: query,
    cancelToken,
  })
}

export function brandDetail(id: string) {
  return axios.get<BaseResponse<Brand>>(`${BRAND}/${encodeURI(id)}`)
}

export function getListStore(
  query: {
    page?: number
    limit?: number
    merchant_id?: string
    sales_channel_id?: string
    status?: MenuPriceCategoryStatus
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<Store>>>(STORE, {
    params: query,
    cancelToken,
  })
}

export function dataStore(id: string) {
  return axios.get<BaseResponse<any>>(`${STORE}?merchant_id=${encodeURI(id)}`)
}
export function dataStoreDetail(id: string) {
  return axios.get<BaseResponse<any>>(`${STORE}/${encodeURI(id)}`)
}

export function updateBrand(id: string, data: any) {
  return axios.put(`${BRAND}/${id}`, data)
}

export function saveBrand(props: BrandFormData, cancelToken?: any) {
  const formData = new FormData()
  appendAllFormData(
    formData,
    [
      {key: 'group_id', name: 'group_id'},
      {key: 'type', name: 'type'},
      {key: 'name', name: 'name'},
      {key: 'phone', name: 'phone'},
      {key: 'logo_data', name: 'logo'},
      {key: 'profile_store_photo_data', name: 'profile_store_photo'},
      {key: 'address', name: 'address'},
      {key: 'lob_id', name: 'lob_id'},
      {key: 'pb1', name: 'pb1'},
      {key: 'pb1_tariff', name: 'pb1_tariff'},
      {key: 'npwp_no', name: 'npwp_no'},
      {key: 'npwp_name', name: 'npwp_name'},
      {key: 'npwp_file_data', name: 'npwp_file'},
      {key: 'pic_name', name: 'pic_name'},
      {key: 'pic_nip', name: 'pic_nip'},
      {key: 'pic_phone', name: 'pic_phone'},
      {key: 'pic_email', name: 'pic_email'},
      {key: 'pic_password', name: 'pic_password'},
      {key: 'status', name: 'status'},
    ],
    props
  )
  if (props.id) {
    return axios.put<BaseResponse<Brand>>(`${BRAND}/${props.id}`, formData, {
      cancelToken,
    })
  }
  return axios.post<BaseResponse<Brand>>(BRAND, formData, {
    cancelToken,
  })
}

export function deleteBrand(id: string, cancelToken: any) {
  return axios.delete<BaseResponse<any>>(`${BRAND}/${id}`, {
    cancelToken,
  })
}

export function updateStoreSetting(id: string, data: POSSetting, cancelToken: any) {
  return axios.put<BaseResponse<Brand>>(`api/v1/merchants/store-settings/${id}`, data, {
    cancelToken,
  })
}
