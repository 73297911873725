export const OrderScreens = {
  ORDERS: {
    PATH: `/orders/:tab`,
    buildPath: (tab: string) => `/orders/${tab}`,
  },
  ADD_POS: {
    PATH: `/orders/pos-payment-method/create`,
  },
  EDIT_POS: {
    PATH: `/orders/pos-payment-method/edit/:id`,
    buildPath: (id: string) => `/orders/pos-payment-method/edit/${id}`,
  },
  ORDERING_POS: {
    PATH: `/orders/pos-payment-method/ordering`,
  },
  EDIT_EFOOD: {
    PATH: `/orders/efood-payment-method/edit/:id`,
    buildPath: (id: string) => `/orders/efood-payment-method/edit/${id}`,
  },
  ORDERING_EFOOD: {
    PATH: `/orders/efood-payment-method/ordering`,
  },
}

export const OrderTabs = {
  ORDER_TIME_LIMIT: {
    LABEL: 'Limit Waktu Order',
    SLUG: 'order-time-limit',
    PATH: OrderScreens.ORDERS.buildPath('order-time-limit'),
  },
  POS_PAYMENT_METHOD: {
    LABEL: 'Metode Pembayaran POS',
    SLUG: 'pos-payment-method',
    PATH: OrderScreens.ORDERS.buildPath('pos-payment-method'),
  },
  EFOOD_PAYMENT_METHOD: {
    LABEL: 'Metode Pembayaran eFOOD',
    SLUG: 'efood-payment-method',
    PATH: OrderScreens.ORDERS.buildPath('efood-payment-method'),
  },
}
