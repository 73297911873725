import React, {useState, useEffect, useCallback, useMemo} from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import {Link} from 'react-router-dom'
import useDebounce from 'src/app/hooks/useDebounce'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getListBrand} from '../redux/BrandCRUD'
import {RootState} from 'src/setup'
import BrandRedux from '../redux/BrandRedux'
import {PageTitle} from 'src/_metronic/layout/core'
import {getTitle} from 'src/app/utils/title-utils'
import Pagination from 'src/app/components/Pagination'
import {getListCorporate} from '../../corporate/redux/CorporateCRUD'
import RegisterBrandModal from './RegisterBrandModal'
import {LabelValueProps, ReactSelectMetronicTheme} from 'src/app/components/CustomReactSelect'
import {SelectComponentsConfig} from 'react-select'
import {usePermissions} from 'src/app/hooks/permissions-hook'
import {AsyncPaginate, OptionsList} from 'react-select-async-paginate'

export const ListBrand = () => {
  // States
  const {hasAccess} = usePermissions({code: 'kelola_list_brand'})
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [filter, setFilter] = useState({
    search: '',
    status: '',
    limit: 10,
    group_id: '',
  })
  const [page, setPage] = useState(1)
  const [filterCorporate, setFilterCorporate] = useState<any>(undefined)

  // Variables
  const dispatch = useDispatch()
  const {data, isLoading, message}: any = useSelector<RootState>(({brand}) => brand, shallowEqual)
  const filterDebounce = useDebounce(filter, 500)

  // Handlers
  const handleChange = (event: any) => {
    const {name, value} = event.target

    setFilter((state) => ({...state, [name]: value}))
  }

  const handleStatus = (value: string) => {
    switch (value) {
      case 'ACTIVE':
        return <span className='badge badge-light-success'>Active</span>
      default:
        return (
          <span className='badge badge-light-dark text-gray-600 text-capitalize'>Inactive</span>
        )
    }
  }

  const fetchCorporate = useCallback(async (text: string, loaded: OptionsList<LabelValueProps>) => {
    try {
      const value = await getListCorporate({
        page: loaded.length === 0 ? 1 : Math.ceil((loaded.length - 1) / 10) + 1,
        limit: 100,
        search: text,
        statuses: ['ACTIVE'],
      })
      const items: any = value.data.data?.items
      const options =
        loaded.length === 0
          ? [
              {value: '', label: 'Semua Corporate'},
              ...items?.map((it: any) => ({value: it.id, label: it.name})),
            ]
          : [...items?.map((it: any) => ({value: it.id, label: it.name}))]
      return {
        options,
        hasMore: options.length + loaded.length <= (value.data.data?.total_item ?? 0),
      }
    } catch (e) {
      return {options: [], hasMore: false}
    }
  }, [])

  // Hooks
  //start::TITLE_FUNC
  const titleAlt = useMemo(() => 'Brand', [])
  const pageTitle = useMemo(() => 'List Brand', [])

  useEffect(() => {
    document.title = getTitle(pageTitle)
  }, [])
  //end::TITLE_FUNC

  useEffect(() => {
    setPage(1)
  }, [filterDebounce])

  useEffect(() => {
    const getBrands = () => {
      dispatch(BrandRedux.actions.setIsLoading(true))
      setTimeout(async () => {
        try {
          const params: any = {page}
          const {limit, search, status, group_id} = filter
          if (limit) params.limit = limit
          if (search) params.search = search
          if (status) {
            params.statuses = [status]
          } else {
            params.statuses = ['ACTIVE', 'INACTIVE']
          }
          if (group_id) params.group_id = group_id

          const result = await getListBrand(params)
          const data = result.data.data

          dispatch(BrandRedux.actions.getListBrand(data, ''))
        } catch (error) {
          dispatch(BrandRedux.actions.getListBrand(undefined, 'Gagal mendapatkan list brand.'))
        }
      }, 1000)
    }

    getBrands()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDebounce, page])

  useEffect(() => {
    setFilter((state) => ({...state, group_id: filterCorporate?.value}))
  }, [filterCorporate])

  return (
    <div>
      <PageTitle description={pageTitle}>{titleAlt}</PageTitle>
      <div className='card-header d-flex align-items-center justify-conten-between'>
        <h1>List Brand</h1>
        <div className='card-toolbar'>
          {hasAccess({access: 'create'}) ? (
            <button
              onClick={() => setShowRegisterModal(true)}
              type='button'
              className='btn btn-sm btn-secondary fw-bold fs-6'
            >
              Registrasi Brand
            </button>
          ) : null}
        </div>
      </div>
      <div className='card-body'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='mb-4 w-lg-200px w-100 position-relative'>
            <InlineSVG
              src={'/media/icons/efood/IconSearch.svg'}
              className='position-absolute translate-middle-y top-50 ms-4 pe-none'
            />
            <input
              placeholder='Cari'
              className='form-control form-control-lg form-control-solid ps-13'
              type='text'
              autoComplete='off'
              onChange={handleChange}
              name='search'
            />
          </div>
          <div className='d-flex align-items-center mt-5 mt-lg-0' style={{marginLeft: 20}}>
            <label
              htmlFor='filterStatus'
              className='form-label ms-lg-4 mt-2'
              style={{minWidth: 'max-content'}}
            >
              Filter Corporate
            </label>
            <AsyncPaginate
              placeholder='Semua Corporate'
              cacheOptions
              defaultOptions
              components={ReactSelectMetronicTheme as SelectComponentsConfig<any, true>}
              getOptionLabel={(data: any) => data.label ?? ''}
              getOptionValue={(data: any) => data.value ?? ''}
              value={filterCorporate}
              loadOptions={fetchCorporate}
              debounceTimeout={500}
              onChange={setFilterCorporate}
              className='ms-3 w-200px'
            />
          </div>
          <div className='d-flex align-items-center mt-5 mt-lg-0' style={{marginLeft: 20}}>
            <label
              htmlFor='filterStatus'
              className='form-label ms-lg-4 mt-2'
              style={{minWidth: 'max-content'}}
            >
              Filter Status
            </label>
            <select
              className='form-select form-select-solid ms-3'
              aria-label='Select example'
              id='filterStatus'
              onChange={handleChange}
              name='status'
            >
              <option value=''>Semua status</option>
              <option value='ACTIVE'>Active</option>
              <option value='INACTIVE'>Inactive</option>
            </select>
          </div>
        </div>
        <div className='overflow-auto position-relative mb-5'>
          {isLoading && <div className='overlay-light'></div>}
          <table className='table table-striped gy-7 gs-7 mt-10'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-600 border-bottom border-gray-200'>
                <th>No.</th>
                <th>Action</th>
                <th className='min-w-100'>Nama Brand</th>
                <th>Corporate</th>
                <th>Kategori Corporate</th>
                <th>Kontak</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.items?.length ? (
                data?.items?.map((item: any, index: number) => (
                  <tr key={`${item.name}_${index}`} className='fw-bold fs-6 align-middle'>
                    <td>{(page - 1) * (filter?.limit || 10) + index + 1}</td>
                    <td>
                      {hasAccess({access: 'update'}) ? (
                        <Link
                          to={`/brand/list/detail/${item.id}/data-brand`}
                          onClick={() => dispatch(BrandRedux.actions.setBrandSelected(item))}
                        >
                          <InlineSVG src='/media/icons/efood/IconEdit.svg' />
                        </Link>
                      ) : null}
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.group?.name}</td>
                    <td>{item?.group?.category}</td>
                    <td>{item?.phone}</td>
                    <td>{handleStatus(item?.status)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className='text-center fw-bold fs-6 bg-light'>
                    {message ? 'Error, try to refresh the page' : 'Data Kosong'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          loading={isLoading}
          page={page}
          limit={Number(filter.limit || 10)}
          setPage={setPage}
          setPerPage={(limit) => setFilter({...filter, limit})}
          maxData={data?.total_item || 0}
        />
      </div>
      <RegisterBrandModal
        show={showRegisterModal}
        handleClose={() => setShowRegisterModal(false)}
        handleSuccess={() => {}}
      />
    </div>
  )
}
