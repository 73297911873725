/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from 'src/_metronic/helpers'
import {Link} from 'react-router-dom'
import {LabelValueProps, ReactSelectMetronicTheme} from 'src/app/components/CustomReactSelect'
import {SelectComponentsConfig} from 'react-select'
import {getListCorporate} from '../../corporate/redux/CorporateCRUD'
import BrandScreens from '../screens'
import {AsyncPaginate, OptionsList} from 'react-select-async-paginate'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const RegisterBrandModal: React.FC<Props> = ({show, handleClose}) => {
  const [corporate, setCorporate] = useState<string>()

  const fetchCorporates = useCallback(
    async (text: string, loaded: OptionsList<LabelValueProps>) => {
      try {
        const value = await getListCorporate({
          page: Math.ceil(loaded.length / 10) + 1,
          limit: 10,
          search: text,
          statuses: ['ACTIVE'],
        })
        const options = value.data.data?.items?.map((it) => ({value: it.id, label: it.name})) ?? []
        return {
          options,
          hasMore: options.length + loaded.length < (value.data.data?.total_item ?? 0),
        }
      } catch (e) {
        return {options: [], hasMore: false}
      }
    },
    []
  )

  return (
    <Modal aria-hidden='true' tabIndex='-1' show={show} onHide={handleClose} centered>
      <div className='modal-header d-flex align-items-center justify-content-between'>
        <h3 className='d-flex align-items-center'>Registrasi Brand</h3>

        {/* begin::Close */}
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body'>
        <div className='fv-row mb-10'>
          <div className='mb-4'>Pilih company corporate untuk menambahkan brand.</div>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
            Nama Corporate*
          </label>
          <AsyncPaginate
            placeholder='Pilih corporate'
            cacheOptions
            defaultOptions
            components={ReactSelectMetronicTheme as SelectComponentsConfig<LabelValueProps, false>}
            loadOptions={fetchCorporates}
            debounceTimeout={500}
            onChange={(value) => {
              setCorporate(value?.value)
            }}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <button
          type='button'
          className={`btn btn-lg btn-light fw-bolder me-4`}
          onClick={handleClose}
        >
          Kembali
        </button>{' '}
        <Link
          to={BrandScreens.REGISTER_BRAND.buildPath(corporate ?? '')}
          className={`btn btn-lg btn-secondary fw-bolder ${corporate ? '' : 'disabled'}`}
        >
          <span className='indicator-label'>Lanjut</span>
        </Link>
      </div>
    </Modal>
  )
}

export default RegisterBrandModal
