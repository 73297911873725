import {useMemo} from 'react'
import {AsideMenuItem} from 'src/_metronic/layout/components/aside/AsideMenuItem'
import {AsideMenuItemWithSub} from 'src/_metronic/layout/components/aside/AsideMenuItemWithSub'
import {PermissionQuery, usePermissions} from '../hooks/permissions-hook'
import AdminScreens from '../modules/admin/Screens'
import BalanceScreens from '../modules/balance/Screens'
import BrandScreens from '../modules/brand/screens'
import CorporateScreens from '../modules/corporate/screens'
import CustomerEFoodScreens from '../modules/customer-efood/screens'
import FeeScreens from '../modules/fee/Screens'
import {LoyaltyTabs} from '../modules/loyalty/Screens'
import RoleManagementScreens from '../modules/role-management/Screens'
import StoreScreens from '../modules/store/Screens'
import AppUserScreens from '../modules/user-hermes/screens'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  permissions?: PermissionQuery
  activePath?: string
}

const menus: Menu[] = [
  {
    title: 'Dashboard',
    children: [
      {
        to: '/dashboard',
        icon: '/media/icons/efood/dashboard.svg',
        title: 'Dashboard',
      },
    ],
  },
  {
    title: 'KELOLA',
    children: [
      {
        to: CorporateScreens.LIST_CORPORATE.PATH,
        title: 'Corporate',
        icon: '/media/icons/efood/IconCorporate.svg',
        permissions: {code: 'kelola_corporate'},
      },
      {
        to: '/brand',
        title: 'Brand',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconSpoonFork.svg',
        children: [
          {
            to: BrandScreens.LIST_BRAND.PATH,
            title: 'List Brand',
            hasBullet: true,
            permissions: {code: 'kelola_list_brand'},
          },
          {
            to: BrandScreens.LIST_VERIFICATION_BRAND.PATH,
            title: 'List Verifikasi Brand',
            hasBullet: true,
            permissions: {code: 'kelola_list_verifikasi_brand'},
          },
        ],
      },
      {
        to: '/store',
        title: 'Store',
        icon: '/media/icons/efood/IconStore.svg',
        children: [
          {
            to: StoreScreens.STORE_LIST.PATH,
            title: 'List Store',
            hasBullet: true,
            permissions: {code: 'kelola_list_store'},
          },
          {
            to: StoreScreens.STORE_VERIFICATION_LIST.PATH,
            title: 'List Verifikasi Store',
            hasBullet: true,
            permissions: {code: 'kelola_list_verifikasi_store'},
          },
          {
            to: StoreScreens.ORDER_HISTORY.PATH,
            title: 'Riwayat Order',
            hasBullet: true,
            permissions: {code: 'kelola_list_store'},
          },
        ],
      },
      {
        to: StoreScreens.SETUP_DATA_BANK.PATH,
        title: 'Setup Data Bank',
        icon: '/media/icons/efood/IconCreditCard.svg',
        permissions: {code: 'kelola_setup_data_bank', access: 'create'},
      },
      {
        to: '/banner',
        icon: '/media/icons/efood/Picture.svg',
        title: 'Setup Banner',
        fontIcon: 'bi-layers',
        permissions: {code: 'kelola_setup_banner', access: 'create'},
      },
      {
        to: '/bulk-upload-menu/list',
        title: 'Bulk Upload Menu',
        fontIcon: 'bi-layers',
        icon: '/media/icons/efood/IconBulkUploadMenu.svg',
        permissions: {code: 'kelola_bulk_upload_menu'},
      },
      {
        to: '/additional-fee',
        title: FeeScreens.ADDITIONAL_FEE_LIST.TITLE,
        icon: '/media/icons/efood/IconMoney.svg',
        permissions: {code: 'kelola_biaya_tambahan'},
      },
      {
        to: BalanceScreens.PENCAIRAN_SALDO.PATH,
        title: 'Pencairan Saldo',
        icon: '/media/icons/efood/IconPencairanSaldo.svg',
        activePath: BalanceScreens.SALDO.PATH,
        permissions: {code: 'kelola_pencairan_saldo'},
      },
      {
        to: LoyaltyTabs.TERM_AND_CONDITION.PATH,
        title: 'Loyalty',
        icon: '/media/icons/efood/IconLoyalty.svg',
        activePath: '/loyalty',
        permissions: {code: 'kelola_loyalty'},
      },
    ],
  },
  {
    title: 'USER',
    children: [
      {
        to: '#',
        title: 'Admin',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconShieldUser.svg',
        children: [
          {
            to: AdminScreens.ADMIN_LIST.PATH,
            title: AdminScreens.ADMIN_LIST.TITLE,
            hasBullet: true,
            permissions: {code: 'user_list_admin'},
          },
        ],
      },
      {
        to: '/user-hermes/',
        title: 'User Hermes',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconUser.svg',
        children: [
          {
            to: AppUserScreens.USER_CORPORATE.PATH,
            title: AppUserScreens.USER_CORPORATE.TITLE,
            hasBullet: true,
            permissions: {code: 'user_list_user_corporate'},
          },
          {
            to: AppUserScreens.USER_CORPORATE_VERIFICATION.PATH,
            title: AppUserScreens.USER_CORPORATE_VERIFICATION.TITLE,
            hasBullet: true,
            permissions: {code: 'user_list_verifikasi_user_corporate'},
          },
          {
            to: AppUserScreens.USER_BRAND.PATH,
            title: AppUserScreens.USER_BRAND.TITLE,
            hasBullet: true,
            permissions: {code: 'user_list_user_brand'},
          },
          {
            to: AppUserScreens.USER_LIST.PATH,
            title: AppUserScreens.USER_LIST.TITLE,
            hasBullet: true,
            permissions: {code: 'user_list_user_store'},
          },
        ],
      },
      {
        to: CustomerEFoodScreens.CUSTOMER_LIST.PATH,
        title: 'Customer eFOOD',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconUsers.svg',
        permissions: {code: 'user_customer_efood'},
      },
      {
        to: '/role-management',
        title: 'Role Management',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconFlower.svg',
        children: [
          {
            to: RoleManagementScreens.ROLE_ADMIN.PATH,
            title: 'Role Admin',
            hasBullet: true,
            permissions: {code: 'user_role_admin'},
          },
          {
            to: RoleManagementScreens.ROLE_USER_CORPORATE.PATH,
            title: 'Role User Corporate',
            hasBullet: true,
            permissions: {code: 'user_role_user_corporate'},
          },
          {
            to: RoleManagementScreens.ROLE_USER_BRAND.PATH,
            title: 'Role User Brand',
            hasBullet: true,
            permissions: {code: 'user_role_user_brand'},
          },
          {
            to: RoleManagementScreens.ROLE_USER_STORE.PATH,
            title: 'Role User Store',
            hasBullet: true,
            permissions: {code: 'user_role_user_store'},
          },
        ],
      },
    ],
  },
  {
    title: 'SETTING',
    children: [
      {
        activePath: '/explore',
        to: '/explore/home-banner',
        title: 'Eksplor',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconCompass.svg',
        permissions: {code: 'setting_eksplor'},
      },
      {
        activePath: '/profil-store',
        to: '/profil-store/category',
        title: 'Profil Store',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconStore.svg',
        permissions: {code: 'setting_profil_store'},
      },
      {
        activePath: '/orders',
        to: '/orders/order-time-limit',
        title: 'Order',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconOrder.svg',
        // permissions: {code: 'setting_profil_store'},
      },
      {
        to: '/contact-profile',
        title: 'Profil eFOOD',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconProfileEFood.svg',
        permissions: {code: 'setting_profil_efood'},
      },
      {
        activePath: '/others',
        to: '/others/lob',
        title: 'Lainnya',
        fontIcon: 'bi-archive',
        icon: '/media/icons/efood/IconOther.svg',
        permissions: {code: 'setting_profil_lainnya'},
      },
    ],
  },
]

const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{menu.title}</span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const {hasAccess} = usePermissions()
  const generated = useMemo(
    () => filterMenus(menus, (menu) => (menu.permissions ? hasAccess(menu.permissions) : true)),
    [hasAccess]
  )
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
