import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace StoreRedux {
  const STORAGE_KEY = 'zeus-store'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetStoreDetail: '[Get Detail Store] Action',
    IsLoading: '[IsLoading] Action',
  }

  const initialBrandState: IStoreState = {
    store: undefined,
    isLoading: undefined,
    message: undefined,
  }

  export interface IStoreState {
    store?: any
    isLoading?: boolean
    message?: string
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['store', 'isLoading', 'message'],
    },
    (state: IStoreState = initialBrandState, action: ActionWithPayload<IStoreState>) => {
      const store = action.payload?.store
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading

      switch (action.type) {
        case actionTypes.GetStoreDetail:
          return {...state, store, message}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        default:
          return state
      }
    }
  )

  export const actions = {
    getStoreDetail: (store: any, message: string) => ({
      type: actionTypes.GetStoreDetail,
      payload: {store, message},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
  }
}

export default StoreRedux
