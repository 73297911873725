export const ExploreScreens = {
  EXPLORE: {
    PATH: '/explore/:tab',
    buildPath: (tab: string) => `/explore/${tab}`,
  },
  BANNER_ORDERING: {
    PATH: '/explore/home-banner/ordering',
  },
  BANNER_ADD: {
    PATH: '/explore/create-banner',
  },
  BANNER_DETAIL: {
    PATH: '/explore/detail-banner/:id',
    buildPath: (id: string) => `/explore/detail-banner/${id}`,
  },
}

export const ExploreTabs = {
  HOME_BANNER: {
    LABEL: 'Home Banner',
    SLUG: 'home-banner',
    PATH: ExploreScreens.EXPLORE.buildPath('home-banner'),
  },
  BUDGET_MEAL: {
    LABEL: 'Budget Meal',
    SLUG: 'budget-meal',
    PATH: ExploreScreens.EXPLORE.buildPath('budget-meal'),
  },
  STORE_SEARCH_RADIUS: {
    LABEL: 'Radius Pencarian Store',
    SLUG: 'radius-store',
    PATH: ExploreScreens.EXPLORE.buildPath('radius-store'),
  },
}
