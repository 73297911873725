/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {getTitle} from 'src/app/utils/title-utils'
import {PageTitle} from '../../../_metronic/layout/core'

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  useEffect(() => {
    document.title = getTitle('Dashboard')
  }, [])
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
    </>
  )
}

export {DashboardWrapper}
