import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace UserRedux {
  const STORAGE_KEY = 'user-hermes'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetUserList: '[Get User List] Action',
    GetUserListBrand: '[Get User List Brand] Action',
    GetUserDetail: '[Get User Detail] Action',
    IsLoading: '[IsLoading] Action',
    SetSuccess: '[SetSuccess] Action',
  }

  const initialBrandState: IUserState = {
    user: undefined,
    data: undefined,
    dataBrand: undefined,
    isLoading: undefined,
    message: undefined,
    success: undefined,
  }

  export interface IUserState {
    user?: any
    data?: any
    dataBrand?: any
    isLoading?: boolean
    message?: string
    success?: string
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['user', 'data', 'isLoading', 'message', 'success'],
    },
    (state: IUserState = initialBrandState, action: ActionWithPayload<IUserState> = {type: ''}) => {
      const user = action.payload?.user
      const data = action.payload?.data
      const dataBrand = action.payload?.dataBrand
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading
      const success = action.payload?.success

      switch (action.type) {
        case actionTypes.GetUserList:
          return {...state, data, message, isLoading: false}
        case actionTypes.GetUserListBrand:
          return {...state, dataBrand, message, isLoading: false}
        case actionTypes.GetUserDetail:
          return {...state, user, message}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        case actionTypes.SetSuccess:
          return {...state, success}
        default:
          return state
      }
    }
  )

  export const actions = {
    getUserList: (data: any, message: string) => ({
      type: actionTypes.GetUserList,
      payload: {data, message},
    }),
    getUserDetail: (brand: any, message: string) => ({
      type: actionTypes.GetUserDetail,
      payload: {brand, message},
    }),
    getUserListBrand: (dataBrand: any, message: string) => ({
      type: actionTypes.GetUserListBrand,
      payload: {dataBrand, message},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
    setSuccess: (success: string) => ({
      type: actionTypes.SetSuccess,
      payload: {success},
    }),
  }
}

export default UserRedux
