const LoyaltyScreens = {
  LOYALTY: {
    PATH: '/loyalty/:tab',
    buildPath: (tab: string) => `/loyalty/${tab}`,
  },
  LOYALTY_PROMO_EFOOD_CREATE: {
    PATH: '/loyalty/efood-promo/create',
    TITLE: 'Tambah Promo',
  },
  LOYALTY_PROMO_EFOOD_DETAIL: {
    PATH: '/loyalty/efood-promo/detail/:discountId',
    buildPath: (discountId?: string) => `/loyalty/efood-promo/detail/${discountId}`,
    TITLE: 'Detail Promo',
  },
  LOYALTY_VOUCHER_CREATE: {
    PATH: '/loyalty/voucher/create',
    TITLE: 'Tambah Voucher',
  },
  LOYALTY_VOUCHER_DETAIL: {
    PATH: '/loyalty/voucher/detail/:id',
    buildPath: (id?: string) => `/loyalty/voucher/detail/${id}`,
    TITLE: 'Detail Voucher',
  },
  LOYALTY_VOUCHER_CODE_CREATE: {
    PATH: '/loyalty/voucher-code/create',
    TITLE: 'Tambah Kode Voucher',
  },
  LOYALTY_VOUCHER_CODE_DETAIL: {
    PATH: '/loyalty/voucher-code/detail/:id',
    buildPath: (id?: string) => `/loyalty/voucher-code/detail/${id}`,
    TITLE: 'Detail Kode Voucher',
  },
  LOYALTY_VOUCHER_PACK_CREATE: {
    PATH: '/loyalty/voucher-pack/create',
    TITLE: 'Tambah Paket Voucher',
  },
  LOYALTY_VOUCHER_PACK_DETAIL: {
    PATH: '/loyalty/voucher-pack/detail/:id',
    buildPath: (id?: string) => `/loyalty/voucher-pack/detail/${id}`,
    TITLE: 'Detail Paket Voucher',
  },
}

export const LoyaltyTabs = {
  TERM_AND_CONDITION: {
    LABEL: 'Syarat & Ketentuan',
    SLUG: 'term-and-condition',
    PATH: LoyaltyScreens.LOYALTY.buildPath('term-and-condition'),
  },
  HOW_TO_USE: {
    LABEL: 'Cara Penggunaan',
    SLUG: 'how-to-use',
    PATH: LoyaltyScreens.LOYALTY.buildPath('how-to-use'),
  },
  EFOOD_PROMO: {
    LABEL: 'Promo eFOOD',
    SLUG: 'efood-promo',
    PATH: LoyaltyScreens.LOYALTY.buildPath('efood-promo'),
    TITLE: 'List Promo eFOOD',
    TITLE_ALT: 'Promo eFOOD',
  },
  VOUCHER: {
    LABEL: 'Voucher',
    SLUG: 'voucher',
    PATH: LoyaltyScreens.LOYALTY.buildPath('voucher'),
  },
  VOUCHER_CODE: {
    LABEL: 'Kode Voucher',
    SLUG: 'voucher-code',
    PATH: LoyaltyScreens.LOYALTY.buildPath('voucher-code'),
  },
  VOUCHER_PACK: {
    LABEL: 'Paket Voucher',
    SLUG: 'voucher-pack',
    PATH: LoyaltyScreens.LOYALTY.buildPath('voucher-pack'),
  },
}

export default LoyaltyScreens
