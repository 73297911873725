import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import BrandRedux from 'src/app/modules/brand/redux/BrandRedux'
import CorporateRedux from 'src/app/modules/corporate/redux/CorporateRedux'
import StoreRedux from 'src/app/modules/store/redux/StoreRedux'
import QueriesRedux from 'src/app/modules/queries/redux/QueriesRedux'
import RolesRedux from 'src/app/modules/role-management/redux/RolesRedux'
import UserRedux from 'src/app/modules/user-hermes/redux/UserRedux'
import AdminRedux from 'src/app/modules/admin/redux/AdminRedux'
import CustomerRedux from 'src/app/modules/customer-efood/redux/CustomerRedux'

export const rootReducer = combineReducers({
  admin: AdminRedux.reducer,
  auth: AuthRedux.reducer,
  brand: BrandRedux.reducer,
  corporate: CorporateRedux.reducer,
  customer: CustomerRedux.reducer,
  queries: QueriesRedux.reducer,
  roles: RolesRedux.reducer,
  store: StoreRedux.reducer,
  user: UserRedux.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([AuthRedux.saga()])
}
