const FeeScreens = {
  ADDITIONAL_FEE_LIST: {
    PATH: '/additional-fee',
    TITLE: 'Biaya Tambahan',
  },
  ADDITIONAL_FEE_CREATE: {
    PATH: '/additional-fee/create',
    TITLE: 'Biaya Tambahan',
  },
  ADDITIONAL_FEE_DETAIL: {
    PATH: '/additional-fee/detail/:id',
    buildPath: (id: string) => `/additional-fee/detail/${id}`,
  },
}

export default FeeScreens
