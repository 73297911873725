const AuthScreens = {
  LOGIN_EMAIL: {
    PATH: '/auth/login/email',
  },
  LOGIN_PHONE: {
    PATH: '/auth/login',
  },
  PHONE_VERIFICATION: {
    PATH: '/auth/phone-verification',
  },
  EMAIL_VERIFICATION: {
    PATH: '/auth/email-verification',
  },
  EMAIL_CUSTOMER_VERIFICATION: {
    PATH: '/verification/email',
  },
  VERIFICATION_SUCCESS: {
    PATH: '/auth/verification-success',
  },
  FORGOT: {
    PATH: '/auth/forgot-password',
  },
  CREATE_NEW_PASSWORD: {
    PATH: '/auth/create-password',
  },
}

export default AuthScreens
