const AdminScreens = {
  ADMIN_LIST: {
    PATH: '/admin/list',
    TITLE: 'List Admin',
  },
  ADD_ADMIN: {
    PATH: '/admin/list/add',
    TITLE: 'Tambah Admin',
  },
  EDIT_ADMIN: {
    PATH: '/admin/list/edit/:id',
    TITLE: 'Edit Admin',
  },
}

export default AdminScreens
