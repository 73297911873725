export const OtherScreens = {
  OTHERS: {
    PATH: `/others/:tab`,
    buildPath: (tab: string) => `/others/${tab}`,
  },
  ADD_BUSINESS_FIELDS: {
    PATH: `/others/lob/create`,
  },
  DETAIL_BUSINESS_FIELDS: {
    PATH: `/others/lob/detail/:id`,
    buildPath: (id: string) => `/others/lob/detail/${id}`,
  },
  ADD_ONBOARDING: {
    PATH: `/others/onboarding/create`,
  },
  ORDERING_ONBOARDING: {
    PATH: `/others/onboarding/ordering`,
  },
  DETAIL_ONBOARDING: {
    PATH: `/others/onboarding/detail/:id`,
    buildPath: (id: string) => `/others/onboarding/detail/${id}`,
  },
}

export const OtherTabs = {
  LIST_OF_BUSINESS_FIELDS: {
    LABEL: 'List Bidang Usaha',
    SLUG: 'lob',
    PATH: OtherScreens.OTHERS.buildPath('lob'),
  },
  // EFOOD_SERVICE_FEE: {
  //   LABEL: 'Biaya Layanan eFOOD',
  //   SLUG: 'efood-service-fee',
  //   PATH: OtherScreens.OTHERS.buildPath('efood-service-fee'),
  // },
  // DISBURSEMENT: {
  //   LABEL: 'Disbursement',
  //   SLUG: 'disbursement',
  //   PATH: OtherScreens.OTHERS.buildPath('disbursement'),
  // },
  ONBOARDING: {
    LABEL: 'Onboarding',
    SLUG: 'onboarding',
    PATH: OtherScreens.OTHERS.buildPath('onboarding'),
  },
  // PUSH_NOTIFICATION: {
  //   LABEL: 'Push Notification',
  //   SLUG: 'push-notification',
  //   PATH: OtherScreens.OTHERS.buildPath('push-notification'),
  // },
  PRIVACY_POLICY: {
    LABEL: 'Kebijakan Privasi',
    SLUG: 'privacy-policy',
    PATH: OtherScreens.OTHERS.buildPath('privacy-policy'),
  },
  TERMS_OF_SERVICE: {
    LABEL: 'Ketentuan Layanan',
    SLUG: 'terms-of-service',
    PATH: OtherScreens.OTHERS.buildPath('terms-of-service'),
  },
}
