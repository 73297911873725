const RoleManagementScreens = {
  ROLE_ADMIN: {
    PATH: '/role-management/role-admin',
  },
  ROLE_USER_CORPORATE: {
    PATH: '/role-management/role-user-corporate',
  },
  ROLE_USER_BRAND: {
    PATH: '/role-management/role-user-brand',
  },
  ROLE_USER_STORE: {
    PATH: '/role-management/role-user-store',
  },
  ROLE_LIST: {
    PATH: '/role-management/:section',
    buildPath: (section: string) => `/role-management/${section}`,
  },
  ROLE_REGISTER: {
    PATH: '/role-management/:section/register',
  },
  ROLE_DETAIL: {
    PATH: '/role-management/:section/detail/:id',
  },
}

export default RoleManagementScreens
