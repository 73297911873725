const AppUserScreens = {
  DETAIL_USER: {
    PATH: '/user-hermes/list-user-store/detail/:id',
    TITLE: 'Detail User Store',
  },
  USER_LIST: {
    PATH: '/user-hermes/list-user-store',
    TITLE: 'List User Store',
  },
  ADD_USER: {
    PATH: '/user-hermes/add-user-store',
    TITLE: 'Add User Store',
  },
  USER_BRAND: {
    PATH: '/user-hermes/brand',
    TITLE: 'List User Brand',
  },
  USER_BRAND_CREATE: {
    PATH: '/user-hermes/brand/create',
  },
  USER_BRAND_DETAIL: {
    PATH: '/user-hermes/brand/detail/:id',
    buildPath: (id?: string) => `/user-hermes/brand/detail/${id}`,
  },
  USER_CORPORATE: {
    PATH: '/user-hermes/corporate',
    TITLE: 'List User Corporate',
  },
  USER_CORPORATE_CREATE: {
    PATH: '/user-hermes/corporate/create',
  },
  USER_CORPORATE_DETAIL: {
    PATH: '/user-hermes/corporate/detail/:id',
    buildPath: (id?: string) => `/user-hermes/corporate/detail/${id}`,
  },
  USER_CORPORATE_VERIFICATION: {
    PATH: '/user-hermes/list-verificaction-corporate',
    TITLE: 'List Verifikasi User Corporate',
  },
  DETAIL_USER_CORPORATE_VERIFICATION: {
    PATH: '/user-hermes/list-verificaction-corporate/detail/:id',
    TITLE: 'Verifikasi User Corporate',
  },
}

export default AppUserScreens
