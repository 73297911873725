import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {CustomerModel} from '../model/customer'

namespace CustomerRedux {
  const STORAGE_KEY = 'user-customer'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetCustomerList: '[Get Customer List] Action',
    GetDataCustomer: '[Get Customer Detail] Action',
    IsLoading: '[IsLoading] Action',
    SetSuccess: '[SetSuccess] Action',
  }

  const initialState: ICustomerState = {
    data: undefined,
    customer: undefined,
    isLoading: undefined,
    message: undefined,
    success: undefined,
  }

  export interface ICustomerState {
    data?: any
    customer?: CustomerModel
    isLoading?: boolean
    message?: string
    success?: string
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['data', 'customer', 'isLoading', 'message', 'success'],
    },
    (state: ICustomerState = initialState, action: ActionWithPayload<ICustomerState>) => {
      const data = action.payload?.data
      const customer = action.payload?.customer
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading
      const success = action.payload?.success

      switch (action.type) {
        case actionTypes.GetCustomerList:
          return {...state, data, message, isLoading: false}
        case actionTypes.GetDataCustomer:
          return {...state, customer, message}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        case actionTypes.SetSuccess:
          return {...state, success}
        default:
          return state
      }
    }
  )

  export const actions = {
    getCustomerList: (data: any, message: string) => ({
      type: actionTypes.GetCustomerList,
      payload: {data, message},
    }),
    getDataCustomer: (customer: any, message: string) => ({
      type: actionTypes.GetDataCustomer,
      payload: {customer, message},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
    setSuccess: (success: string) => ({
      type: actionTypes.SetSuccess,
      payload: {success},
    }),
  }
}

export default CustomerRedux
