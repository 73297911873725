const CustomerEFoodScreens = {
  CUSTOMER_LIST: {
    PATH: '/customer-efood/list',
    TITLE: 'Customer eFOOD',
  },
  CUSTOMER_USER: {
    PATH: '/customer-efood/list/detail/:id/:tab',
    TITLE: 'Detail Customer eFOOD',
    buildPath: (id: string, tab: string) => `/customer-efood/list/detail/${id}/${tab}`,
  },
  DETAIL_ORDER_HISTORY: {
    PATH: '/customer-efood/list/detail/:id/:tab/detail/:order_id',
    buildPath: (id: string, tab: string, order_id: string) =>
      `/customer-efood/list/detail/${id}/${tab}/detail/${order_id}`,
    TITLE: 'Detail Riwayat Order',
  },
}

export default CustomerEFoodScreens
