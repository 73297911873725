export const StoreProfileScreens = {
  STORE_PROFILE: {
    PATH: '/profil-store/:tab',
    buildPath: (tab: string) => `/profil-store/${tab}`,
  },
  ADD_STORE_CATEGORY: {
    PATH: '/profil-store/category/create',
  },
  DETAIL_STORE_CATEGORY: {
    PATH: '/profil-store/category/:id',
    buildPath: (id: string) => `/profil-store/category/${id}`,
  },
  DETAIL_PRICE_RANGE: {
    PATH: '/profil-store/price-range/:id',
    buildPath: (id: string) => `/profil-store/price-range/${id}`,
  },
  ADD_REVIEW_CONTENT: {
    PATH: '/profil-store/review-content/create',
  },
  DETAIL_REVIEW_CONTENT: {
    PATH: '/profil-store/review-content/detail/:id',
    buildPath: (id: string) => `/profil-store/review-content/detail/${id}`,
  },
  ORDERING_REVIEW_CONTENT: {
    PATH: '/profil-store/review-content/ordering',
  },
}

export const StoreProfileTabs = {
  STORE_CATEGORY: {
    LABEL: 'Kategori Toko/Makanan',
    SLUG: 'category',
    PATH: StoreProfileScreens.STORE_PROFILE.buildPath('category'),
  },
  PRICE_RANGE: {
    LABEL: 'Rentang Harga',
    SLUG: 'price-range',
    PATH: StoreProfileScreens.STORE_PROFILE.buildPath('price-range'),
  },
  REVIEW_CONTENT: {
    LABEL: 'Konten Ulasan',
    SLUG: 'review-content',
    PATH: StoreProfileScreens.STORE_PROFILE.buildPath('review-content'),
  },
}
