import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {ContactProfileScreens} from '../modules/contact-profile/Screens'
import {ExploreScreens} from '../modules/explore/Screens'
import LoyaltyScreens from '../modules/loyalty/Screens'
import {OrderScreens} from '../modules/order/Screens'
import {OtherScreens} from '../modules/others/Screens'
import {StoreProfileScreens} from '../modules/store-profile/Screens'
import StoreScreens from '../modules/store/Screens'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
  const ProfileRoutes = lazy(() => import('../modules/profile/ProfileRoutes'))
  const BrandPage = lazy(() => import('../modules/brand/BrandPage'))
  const BrandDetailpage = lazy(() => import('../modules/brand/BrandDetailPage'))
  const BrandVerificationPage = lazy(() => import('../modules/brand/BrandRoutes'))
  const CoporateDetailPage = lazy(() => import('../modules/corporate/CorporateDetailPage'))
  const CorporatePage = lazy(() => import('../modules/corporate/CorporatePage'))
  const SetupBannersPage = lazy(() => import('../modules/setup-banner/SetupBannerPage'))
  const RoleManagementPage = lazy(() => import('../modules/role-management/RoleManagementRoutes'))
  const UserHermesPage = lazy(() => import('../modules/user-hermes/AppUserRoutes'))
  const StorePage = lazy(() => import('../modules/store/StoreRoutes'))
  const SetupDataBank = lazy(() => import('../modules/store/page/StoreBankPage'))
  const AdminRoutes = lazy(() => import('../modules/admin/AdminRoutes'))
  const CustomerEFoodPage = lazy(() => import('../modules/customer-efood/CustomerEFoodRoutes'))
  const BulkUploadMenu = lazy(() => import('../modules/bulk-upload/BulkUploadRoutes'))
  const Fee = lazy(() => import('../modules/fee/FeeRoutes'))
  const Balance = lazy(() => import('../modules/balance/BalanceRoutes'))
  const Loyalty = lazy(() => import('../modules/loyalty/LoyaltyRoutes'))
  const Explore = lazy(() => import('../modules/explore/ExploreRoutes'))
  const StoreProfile = lazy(() => import('../modules/store-profile/StoreProfileRoutes'))
  const Others = lazy(() => import('../modules/others/OthersRoutes'))
  const ContactProfile = lazy(() => import('../modules/contact-profile/ContactProfileRoutes'))
  const Order = lazy(() => import('../modules/order/OrderRoutes'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/profile' component={ProfileRoutes} />
        <Route path='/brand/list/detail' component={BrandDetailpage} />
        <Route path='/brand/list' component={BrandPage} />
        <Route path='/brand/verification' component={BrandVerificationPage} />
        <Route path='/corporate/list/detail' component={CoporateDetailPage} />
        <Route path='/corporate' component={CorporatePage} />
        <Route path='/admin' component={AdminRoutes} />
        <Route path='/banner' component={SetupBannersPage} />
        <Route path='/store' component={StorePage} />
        <Route path={StoreScreens.SETUP_DATA_BANK.PATH} component={SetupDataBank} />
        <Route path='/role-management' component={RoleManagementPage} />
        <Route path='/user-hermes' component={UserHermesPage} />
        <Route path='/customer-efood' component={CustomerEFoodPage} />
        <Route path='/bulk-upload-menu/list' component={BulkUploadMenu} />
        <Route path='/additional-fee' component={Fee} />
        <Route path='/balance' component={Balance} />
        <Route path={LoyaltyScreens.LOYALTY.PATH} component={Loyalty} />
        <Route path={ExploreScreens.EXPLORE.PATH} component={Explore} />
        <Route path={StoreProfileScreens.STORE_PROFILE.PATH} component={StoreProfile} />
        <Route path={OtherScreens.OTHERS.PATH} component={Others} />
        <Route path={ContactProfileScreens.CONTACT_PROFILE.PATH} component={ContactProfile} />
        <Route path={OrderScreens.ORDERS.PATH} component={Order} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
