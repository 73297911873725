import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {Store} from '../../store/models/Store'
import {Brand} from '../models/Brand'

namespace BrandRedux {
  const STORAGE_KEY = 'hermes-brand'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetListBrand: '[Get List Brand] Action',
    GetBrandDetail: '[Get Detail Brand] Action',
    GetDataStore: '[Get Data Store] Action',
    IsLoading: '[IsLoading] Action',
    GetMenuPriceCategory: '[Get Menu Price Category] Action',
    GetMenuSalesChannel: '[Get Menu Sales Channel] Action',
    GetMenuVariation: '[Get Menu Variation] Action',
    SetSuccess: '[Set Success] Action',
    SetBrandSelected: '[Set Brand Selected] Action',
    SetBrand: '[Set Brand] Action',
  }

  const initialBrandState: IBrandState = {
    brand: undefined,
    data: undefined,
    menuPriceCategory: undefined,
    menuSalesChannel: undefined,
    menuVariation: undefined,
    isLoading: undefined,
    message: undefined,
    dataStore: {},
  }

  export interface IBrandState {
    brand?: any
    data?: any
    menuPriceCategory?: any
    menuSalesChannel?: any
    menuVariation?: any
    isLoading?: boolean
    message?: string
    dataStore?: Store
    brandSelected?: Brand
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: [
        'brand',
        'data',
        'menuPriceCategory',
        'menuSalesChannel',
        'menuVariation',
        'isLoading',
        'message',
      ],
    },
    (state: IBrandState = initialBrandState, action: ActionWithPayload<IBrandState>) => {
      const data = action.payload?.data
      const brand = action.payload?.brand
      const menuPriceCategory = action.payload?.menuPriceCategory
      const menuSalesChannel = action.payload?.menuSalesChannel
      const menuVariation = action.payload?.menuVariation
      const dataStore = action.payload?.dataStore
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading
      const brandSelected = action.payload?.brandSelected

      switch (action.type) {
        case actionTypes.GetListBrand:
          return {...state, data, message, isLoading: false}
        case actionTypes.GetBrandDetail:
          return {...state, brand, message}
        case actionTypes.GetDataStore:
          return {...state, dataStore, message}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        case actionTypes.GetMenuPriceCategory:
          return {...state, menuPriceCategory}
        case actionTypes.GetMenuSalesChannel:
          return {...state, menuSalesChannel}
        case actionTypes.GetMenuVariation:
          return {...state, menuVariation}
        case actionTypes.SetSuccess:
          return {...state, message}
        case actionTypes.SetBrandSelected:
          return {...state, brandSelected}
        case actionTypes.SetBrand:
          return {...state, brand}
        default:
          return state
      }
    }
  )

  export const actions = {
    getListBrand: (data: any, message: string) => ({
      type: actionTypes.GetListBrand,
      payload: {data, message},
    }),
    getBrandDetail: (brand: any, message: string) => ({
      type: actionTypes.GetBrandDetail,
      payload: {brand, message},
    }),
    getMenuPriceCategory: (menuPriceCategory: any, message: string) => ({
      type: actionTypes.GetMenuPriceCategory,
      payload: {menuPriceCategory, message},
    }),
    getMenuSalesChannel: (menuSalesChannel: any, message: string) => ({
      type: actionTypes.GetMenuSalesChannel,
      payload: {menuSalesChannel, message},
    }),
    getMenuVariation: (menuVariation: any, message: string) => ({
      type: actionTypes.GetMenuVariation,
      payload: {menuVariation, message},
    }),
    getDataStore: (dataStore: any, message: string) => ({
      type: actionTypes.GetDataStore,
      payload: {dataStore, message},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
    setSuccess: (message: string) => ({
      type: actionTypes.SetSuccess,
      payload: {message},
    }),
    setBrandSelected: (brandSelected: Brand) => ({
      type: actionTypes.SetBrandSelected,
      payload: {brandSelected},
    }),
    setBrand: (brand: Brand) => ({
      type: actionTypes.SetBrand,
      payload: {brand},
    }),
  }
}

export default BrandRedux
