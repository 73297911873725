import axios from 'axios'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {appendAllFormData} from 'src/app/utils/input-utils'
import {Corporate, CorporateFormData} from '../models/Corporate'

export const CORPORATE = `/api/v1/merchants/groups`

export function getListCorporate(params?: {}, cancelToken?: any) {
  return axios.get<BaseResponse<Pagination<Corporate>>>(CORPORATE, {params, cancelToken})
}

export function getDetailCorporate(id: string) {
  return axios.get<BaseResponse<Corporate>>(`${CORPORATE}/${encodeURI(id)}`)
}

export function saveCorporate(props: CorporateFormData, cancelToken?: any) {
  const formData = new FormData()
  appendAllFormData(
    formData,
    [
      {key: 'category', name: 'category'},
      {key: 'name', name: 'name'},
      {key: 'phone', name: 'phone'},
      {key: 'address', name: 'address'},
      {key: 'siup_no', name: 'siup_no'},
      {key: 'siup_file_data', name: 'siup_file'},
      {key: 'akta_pendirian_file_data', name: 'akta_pendirian_file'},
      {key: 'akta_perubahan_file_data', name: 'akta_perubahan_file'},
      {key: 'npwp_no', name: 'npwp_no'},
      {key: 'npwp_file_data', name: 'npwp_file'},
      {key: 'director_name', name: 'director_name'},
      {key: 'director_nip', name: 'director_nip'},
      {key: 'director_phone', name: 'director_phone'},
      {key: 'director_email', name: 'director_email'},
      {key: 'director_identity_type', name: 'director_identity_type'},
      {key: 'director_id_no', name: 'director_id_no'},
      {key: 'director_id_file_data', name: 'director_id_file'},
      {key: 'director_id_face_file_data', name: 'director_id_face_file'},
      {key: 'pic_operational_name', name: 'pic_operational_name'},
      {key: 'pic_operational_nip', name: 'pic_operational_nip'},
      {key: 'pic_operational_email', name: 'pic_operational_email'},
      {key: 'pic_operational_phone', name: 'pic_operational_phone'},
      {key: 'pic_finance_name', name: 'pic_finance_name'},
      {key: 'pic_finance_nip', name: 'pic_finance_nip'},
      {key: 'pic_finance_email', name: 'pic_finance_email'},
      {key: 'pic_finance_phone', name: 'pic_finance_phone'},
      {key: 'director_password', name: 'director_password'},
      {key: 'pic_operational_password', name: 'pic_operational_password'},
      {key: 'pic_finance_password', name: 'pic_finance_password'},
      {key: 'status', name: 'status'},
    ],
    props
  )
  if (props.id) {
    return axios.put<BaseResponse<Corporate>>(`${CORPORATE}/${props.id}`, formData, {
      cancelToken,
    })
  }
  return axios.post<BaseResponse<Corporate>>(CORPORATE, formData, {
    cancelToken,
  })
}

export function deleteCorporate(id: string, cancelToken: any) {
  return axios.delete<BaseResponse<any>>(`${CORPORATE}/${id}`, {
    cancelToken,
  })
}

export function updateCorporate(id: string, data: any) {
  return axios.put(`${CORPORATE}/${id}`, data)
}
