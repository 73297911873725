const ProfileScreens = {
  PROFILE: {
    PATH: '/profile',
  },
  PASSWORD: {
    PATH: '/profile/password',
  },
}

export default ProfileScreens
